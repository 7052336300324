import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";

function onRedirectCallback(res) {
  console.log("login Redirect done")
}

ReactDOM.render(
  <Auth0Provider
    domain="greenapes.auth0.com"
    clientId="jOJpTBUrnGpu827zhsOvUKiQiy2ebTxT" //6VF4Tw4DJWAEgVnSIVwDHp1YG9tLgtKj"
    redirectUri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
    // audience="https://greenapes.auth0.com/api/v2/"
    // scope="read:current_user update:current_user_metadata"
  >
    <App />
  </Auth0Provider>,
  document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
