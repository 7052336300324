import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './App.css';
import { Dataclips } from './components/dataclips';
import { Dataclip, NewDataclip } from './components/dataclip';
// import { dataclipsStore } from "./State";
import { Button, List, ListItem} from "@material-ui/core/";
// import { Provider } from 'react-redux'
// import store from './redux/store'
import { useAuth0 } from "@auth0/auth0-react";
import React from 'react';


require('./base.css')


function App() {

  const { user, isAuthenticated, isLoading, loginWithRedirect, logout, error, getIdTokenClaims } = useAuth0();

  getIdTokenClaims().then((t) => {
    if (t) {
      localStorage.token = t.__raw;
      console.log("token", t.__raw)
    }
  })
  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    // <Provider className="App" store={store}>
    <div className="App">
      <Router>
        <header>
          <h1>
            <Link to="/"><span style={{ color: "white" }}>Greenapes dataclips</span> </Link>
          </h1>
          <div style={{ display: 'flex' }}>
            {isAuthenticated ? (
              <List dense={true} style={{ background: "white", borderRadius: '8px', marginRight: '20px' }}>
                <ListItem>
                  <span>{user.email}</span>&nbsp;&nbsp;
                  <Button variant="contained" onClick={logout}>Logout</Button>
                </ListItem>
              </List>
            ) : (
              <><Button variant="contained" color="primary" onClick={() => loginWithRedirect()}>Login</Button>&nbsp;&nbsp;</>
            )}
            <Button variant="contained" color="secondary"><Link to="/dataclip">Crea nuovo</Link></Button>
          </div>
        </header>
        {isAuthenticated &&
          <main>
            {error &&
              <h3>ERROR {error.message}</h3>
            }
            <Switch>
              <Route exact path="/" render={(props) => (
                <Dataclips
                  {...props}
                />
              )} />
              <Route exact path="/dataclip/:dataclipid" render={(props) => (
                <Dataclip
                  {...props}
                />
              )} />
              <Route exact path="/dataclip" component={props => (
                <NewDataclip
                  {...props}
                />
              )} />
            </Switch>
          </main>
        }
      </Router>
    </div>
    // </Provider>
  );
}

export default App;
