import DataclipApi from '../services/dataclips';


const setDataclips = (p) => {
    return {
        type: 'dataclips/set',
        payload: p
    }
}


// export const getDataclips = () => {
//     return (dispatch, getState) => {
//         if (getState() && getState().length > 0) {
//             return Promise.resolve(getState())
//         }
//         return DataclipApi.list()
//             .then(res => dispatch(setDataclips(res)))
//             .catch(err => dispatch(() => { }))
//     }
// }

// const store = {
//     dataclips: [],
//     deletedDataclips: []
// }

export const getDataclips = (deleted) => {
    // if (deleted && store.deletedDataclips.length > 0) {
    //     return Promise.resolve(store.deletedDataclips)
    // }
    // if (!deleted && store.dataclips.length > 0) {
    //     return Promise.resolve(store.dataclips)
    // }
    return DataclipApi.list(deleted)
}

export const getDataclip = (id) => {
    return DataclipApi.get(id)
}

export const saveDataclip = (dataclip) => {
    return DataclipApi.save(dataclip)
}