import * as React from "react";
import {
    Link,
} from "react-router-dom";
import { List, ListItem, Tab, Tabs, Paper } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import CloudOff from '@material-ui/icons/CloudOff';
import Divider from '@material-ui/core/Divider';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import PropTypes from 'prop-types';
import { SearchInput } from '../../widgets/search';
import PublicIcon from '@material-ui/icons/Public';
import LockIcon from '@material-ui/icons/Lock';
import { getDataclips } from "../../actions/dataclips";


export class Dataclips extends React.Component {

    state = {
        dataclips: [],
        filterText: '',
        tab: 0
    }

    static propTypes = {
        dataclips: PropTypes.any,
    }

    componentDidMount() {
        getDataclips().then(dataclips => {
            this.setState({ dataclips })
        })
        .catch(() => { })
    }

    onSearchChange = (e) => {
        this.setState({ filterText: e.target.value })
    }

    handleChange = (e, newValue) => {
        let deleted = false
        if(newValue === 1) {
            deleted = true
        }
        this.setState({ tab: newValue })
        getDataclips(deleted).then(dataclips => {
            this.setState({ dataclips })
        })
        .catch(() => { })
    }

    render() {

        const { deleted } = this.props;
        const { dataclips, filterText, tab } = this.state;

        return (
            <>
                <Paper square>
                    <Tabs value={tab} onChange={this.handleChange} aria-label="simple tabs example">
                        <Tab label="Online / Offline" />
                        <Tab label="Eliminati" />
                    </Tabs>
                </Paper>
                <div className="dataclips card">
                    <div className="row-end">
                        <SearchInput onChange={this.onSearchChange} />
                    </div>
                    <List style={{ background: deleted ? 'gray' : 'white' }}>
                        {dataclips
                            .filter(d => d.title.indexOf(filterText) !== -1)
                            .map(d => (
                                <Link key={d.id}
                                    to={`/dataclip/${d.id}`}>
                                    <Divider />
                                    <ListItem >
                                        <ListItemIcon>
                                            {d.online ? (
                                                <CloudDoneIcon />
                                            ) : (
                                                <CloudOff />
                                            )}
                                            &nbsp;&nbsp;&nbsp;
                                            {d.private ? (
                                                <LockIcon />
                                            ) : (
                                                <PublicIcon />
                                            )}
                                            &nbsp;&nbsp;&nbsp;
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={d.title}
                                            secondary={d.database}
                                        />
                                        <ListItemSecondaryAction>
                                            {`${d.lastCall ? new Date(d.lastCall).toLocaleString() : ''}`}<badge>{d.called}</badge>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <Divider />
                                </Link>
                            ))}
                    </List>
                </div>
            </>
        )
    }
}

