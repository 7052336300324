import config from '../config';
import { opts } from './login'

const baseUrl = config.baseUrl;

class DataclipApi {

    list(deleted) {
        return fetch(`${baseUrl}/admin/dataclips${deleted ? '?deleted=true' : ''}`, opts(localStorage.token))
            .then(res => res.json())
            .catch(err => {
                if (window.location.href.indexOf("?code=") !== 1) {
                    window.location.href = window.location.href.split("?")[0]
                }
                console.error("Error fetching dataclips", err)
                throw new Error("error")
            })
    }

    get(dataclip_id) {
        return fetch(`${baseUrl}/admin/dataclip/${dataclip_id}`, opts(localStorage.token))
            .then(res => res.json())
            .catch(err => {
                console.error("Error fetching dataclips", err)
                throw new Error("error")
            })
    }

    save(dataclip) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": opts(localStorage.token).headers.Authorization
            },
            body: JSON.stringify(dataclip)
        };
        const q = fetch(`${baseUrl}/admin/dataclip`, requestOptions)
        const q2 = q.then(res => res.json())
        return q2
    }

    restore(dataclip_id) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": opts(localStorage.token).headers.Authorization
            },
            body: JSON.stringify({})
        };
        const q = fetch(`${baseUrl}/admin/dataclip/restore/${dataclip_id}`, requestOptions)
        const q2 = q.then(res => res.json())
        return q2
    }

    delete(dataclip) {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                "Authorization": opts(localStorage.token).headers.Authorization
            }
        }
        return fetch(`${baseUrl}/admin/dataclip/${dataclip.id}`, requestOptions)
    }
}

export default new DataclipApi();