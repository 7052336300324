import { opts } from './login'

class QueryApi {

    execute(url) {
        const q = fetch(url, opts(localStorage.token))
        const q2 = q
            .then(resp => {
                if (!resp.ok) {
                    return resp.text().then(err => {
                        throw err
                    })
                }
                return resp.json()
            })
        return q2
    }
}

export default new QueryApi();