import * as React from "react";
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from "@material-ui/core/TextField";
import { CircularProgress, Snackbar, Switch, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, IconButton } from "@material-ui/core";
import CloudDoneIcon from "@material-ui/icons/CloudDone";
import CloudOff from "@material-ui/icons/CloudOff";
import PublicIcon from "@material-ui/icons/Public";
import LockIcon from "@material-ui/icons/Lock";
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import CloseOutlined from "@material-ui/icons/CloseOutlined";
import MuiAlert from '@material-ui/lab/Alert';
import QueryApi from "../../services/query"
import DataclipApi from "../../services/dataclips"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { getDataclip } from '../../actions/dataclips';


require('./index.css')

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#3c3c3c",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export class Dataclip extends React.Component {

    state = {
        dataclip: undefined,
        results: [],
        alert: {},
        query: undefined,
        online: false,
        private: false,
        loading: false,
        variables: {}
    }

    componentDidMount() {
        getDataclip(this.props.match.params.dataclipid)
            .then(dataclip => {
                this.setState({ dataclip })
            })
            .catch(() => { })
    }

    onRun = (dataclip) => {
        this.setState({ loading: true, results: [] })
        let query = dataclip.apis.apiCsvArray;
        Object.keys(this.state.variables).forEach(k => {
            query += `${query.indexOf("?") !== -1 ? '&' : '?'}${k}=${this.state.variables[k]}`
        })
        QueryApi.execute(query)
            .then(res => {
                for (var i = 1; i < res.length; i++) {
                    for (var j = 0; j < res[i].length; j++) {
                        res[i][j] = JSON.stringify(res[i][j])
                    }
                }
                this.setState({ loading: false, results: res, alert: {} })
            })
            .catch(err => {
                this.setState({ loading: false, alert: { status: "error", message: `${err}` }, results: [] })
            })
    }

    onSave = (dataclip) => {
        return DataclipApi.save(dataclip)
            .then(res => {
                this.setState({ alert: { status: "success", message: "Dataclip salvato" } })
            })
            .catch(err => {
                this.setState({ alert: { status: "error", message: `${err}` } })
            })
    }

    onRestore = (dataclip) => {
        return DataclipApi.restore(dataclip.id)
            .then(res => {
                this.setState({ alert: { status: "success", message: "Dataclip ripristinato" } })
                setTimeout(() => { this.props.history.push("/") }, 3000)
            })
            .catch(err => {
                this.setState({ alert: { status: "error", message: `${err}` } })
            })
    }

    onDelete = (dataclip) => {
        return DataclipApi.delete(dataclip)
            .then(() => {
                this.props.history.push("/")
            })
    }

    changeOnline = (dataclip) => {
        this.setState(p => {
            dataclip.online = !p.online;
            return { online: !p.online }
        })
    }
    privateChange = (dataclip) => {
        this.setState(p => {
            dataclip.private = !p.private;
            return { private: !p.private }
        })
    }

    changeTitle = (e, dataclip) => {
        dataclip.title = e.target.value
        this.setState({ title: e.target.value })
    }

    onQueryChange = (e, dataclip) => {
        dataclip.query = e.target.value;
        this.setState({ query: dataclip.query })
    }

    addVariable = (key, value) => {
        this.setState(p => {
            let variables = p.variables
            variables[key] = value
            return { variables }
        })
    }

    setKey = (e) => {
        this.setState({ key: e.target.value })
    }

    setValue = (e) => {
        this.setState({ value: e.target.value })
    }

    goBack = () => {
        this.props.history.push("/")
    }

    render() {
        const { dataclip, results } = this.state;
        if (dataclip && this.state.query === undefined) {
            this.setState({ query: dataclip.query, online: dataclip.online, title: dataclip.title, private: dataclip.private })
        }
        return (
            !dataclip ? (
                <></>
            ) : (
                <div className="dataclip card">
                    <List>
                        <ListItem>
                            <IconButton onClick={this.goBack}>
                                <ArrowBackRoundedIcon />
                            </IconButton>
                            <ListItemIcon>
                                {dataclip.private ? (
                                    <LockIcon />
                                ) : (
                                    <PublicIcon />
                                )}
                            </ListItemIcon>
                            <ListItemIcon>
                                {dataclip.online ? (
                                    <CloudDoneIcon />
                                ) : (
                                    <CloudOff />
                                )}
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <React.Fragment>
                                        <TextField value={this.state.title} onChange={(e) => this.changeTitle(e, dataclip)} />
                                    </React.Fragment>
                                }
                                secondary={dataclip.database}
                            />
                            <ListItemSecondaryAction>
                                <b>API status</b>
                                <Switch
                                    title="Toggle per rendere questa api attiva"
                                    checked={this.state.online}
                                    onChange={() => this.changeOnline(dataclip)}
                                    color="primary"
                                />
                                <br />
                                <b>Private</b>
                                <Switch
                                    title="Toggle per rendere questa api sotto autenticazione"
                                    checked={this.state.private}
                                    onChange={() => this.privateChange(dataclip)}
                                    color="primary"
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                    <div className="container">

                        <TextField
                            className="fullwidth"
                            label="query"
                            onChange={(e) => this.onQueryChange(e, dataclip)}
                            value={this.state.query}
                            multiline
                            rows={20}
                            cols={20}
                            variant="outlined"
                        />
                        {dataclip.private && dataclip["Authorization header"] &&
                            <p><b>Authorization header</b> {dataclip["Authorization header"]}</p>
                        }
                        {dataclip.apis && Object.keys(dataclip.apis).map(api => (
                            <p><b>{api}:</b> {dataclip.apis[api]}</p>
                        ))}
                        <h3>API Stats:</h3>
                        <ul>
                            <li>Called: {dataclip.called}</li>
                            <li>Last call: {dataclip.lastCall}</li>
                        </ul>
                        <div>
                            <TextField
                                variant="outlined"
                                label="nome variabile"
                                onChange={this.setKey}
                            />
                            <TextField
                                variant="outlined"
                                label="valore variabile"
                                onChange={this.setValue}
                            />
                            <Button variant="contained" onClick={() => this.addVariable(this.state.key, this.state.value)}>
                                Aggiungi variabile all'esecuzione
                            </Button>
                            <hr />
                        </div>
                        {Object.keys(this.state.variables).length > 0 &&
                            <h3>Variabili aggiunte alla query</h3>
                        }
                        {Object.keys(this.state.variables).map(k => (
                            <p><b>:{k}</b> {this.state.variables[k]}</p>
                        ))}
                        {dataclip.deleted ? (
                            <Button variant="contained" color="primary" onClick={() => this.onRestore(dataclip)}>Ripristina</Button>
                        ) : (
                            <>
                                <Button variant="contained" color="secondary" onClick={() => this.onRun(dataclip)}>Esegui {Object.keys(this.state.variables).length > 0 ? 'con variabili aggiunte' : ''}</Button>
                                <Button variant="contained" color="primary" onClick={() => this.onSave(dataclip)}>Salva</Button>
                                <Button variant="contained" color="red" onClick={() => this.onDelete(dataclip)}>Elimina</Button>
                            </>
                        )}
                        {this.state.loading && <center><br /><CircularProgress /></center>}
                        {results.length > 0 &&
                            <>
                                <br />
                                <br />
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <StyledTableRow>
                                                {results[0].map(head => (
                                                    <StyledTableCell key={head}><b>{head}</b></StyledTableCell>
                                                ))}
                                            </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                            {results.slice(1, results.length).map(row => (
                                                <StyledTableRow key={`${row}`}>
                                                    {row.map(value => (
                                                        <StyledTableCell kew={value}>{value}</StyledTableCell>
                                                    ))}
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        }
                        <Snackbar open={this.state.alert.status} >
                            <Alert severity={this.state.alert.status}>
                                {this.state.alert.message}
                                <Button color="inherit" size="small" onClick={() => this.setState({ alert: {} })}>
                                    <CloseOutlined />
                                </Button>
                            </Alert>
                        </Snackbar>
                    </div>
                </div >
            )
        )
    }
}

export class NewDataclip extends React.Component {

    state = {}

    onInputchange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    onSubmitForm = () => {
        console.log(this.state)
        DataclipApi.save(this.state)
            .then(success => {
                this.props.history.push(`/dataclip/${success.id}`)
            })
    }

    render() {
        return (
            <div className="new-dataclip card">
                <TextField name="title"
                    label="Title"
                    value={this.state.title}
                    onChange={this.onInputchange}
                />
                <FormControl>
                    <InputLabel id="demo-simple-select-label">Database</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        name="database"
                        value={this.state.database}
                        onChange={this.onInputchange}
                    >
                        <MenuItem></MenuItem>
                        <MenuItem value="gopongo">gopongo</MenuItem>
                        <MenuItem value="api-greenapes">api-greenapes</MenuItem>
                        <MenuItem value="staging-greenapes">staging-greenapes</MenuItem>

                    </Select>
                </FormControl>
                <TextField
                    label="query"
                    name="query"
                    value={this.state.query}
                    onChange={this.onInputchange}
                    multiline
                    rows={4}
                    variant="outlined"
                />
                <Button onClick={this.onSubmitForm} variant="contained" color="primary">Salva</Button>
            </div>
        )
    }
}